import api from "@/base/utils/request";

// 获取互动列表
export const InteractionList = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/index",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/putBack",
    method: "post",
    data
  });
};

// 通过审核
export const Checked = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/check",
    method: "post",
    data
  });
};

// 不通过审核
export const NoCheck = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/nocheck",
    method: "post",
    data
  });
};

// 禁言用户
export const BannedUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/banned",
    method: "post",
    data
  });
};

//解禁用户
export const NoBannedUser = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/remove",
    method: "post",
    data
  });
};

//获取互动内容
export const chatContent = data => {
  return api({
    url: "/admin/cyc_activity/activity_chat/chatContent",
    method: "post",
    data
  });
};