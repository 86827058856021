<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="form.tab !== 'user'?118:60"
    :toggleCollapseEnable="form.tab === 'deleted' || form.tab === 'thenCheck'?true:false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from" @submit.native.prevent>
      <div class="filter-form" v-if="form.tab !== 'user'">
        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item v-if="form.tab === 'thenCheck' || form.tab === 'deleted'" label="审核状态："
          prop="check_status">
          <el-select v-model="check_status" placeholder="请选审核择状态">
            <el-option v-for="item in checkStatusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.tab === 'user'" label="用户状态：" prop="user_status">
          <el-select v-model="user_status" placeholder="请选择用户状态">
            <el-option v-for="item in userStatusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型：" prop="type">
          <el-select v-model="type" placeholder="请选择用户类型">
            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.tab !== 'user'" label="互动内容：" prop="content_keyword">
          <el-input v-model="form.content_keyword" placeholder="请输入互动内容"></el-input>
        </el-form-item>
        <el-form-item v-if="form.tab !== 'deleted' && form.tab !== 'thenCheck'" label="会员名称："
          prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入会员名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="form.tab === 'deleted' || form.tab === 'thenCheck'">
        <el-form-item label="会员名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入会员名称"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      checkStatusOptions: [
        { name: "已通过", id: 2 },
        { name: "未通过", id: 1 },
      ],
      userStatusOptions: [
        { name: "被禁言", id: 0 },
        { name: "可互动", id: 1 },
      ],
      typeOptions: [
        { name: "会员", id: 0 },
        { name: "游客", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    user_status: {
      get() {
        return this.form.user_status == -1 ? "" : this.form.user_status;
      },
      set(val) {
        this.form.user_status = val;
      },
    },
    check_status: {
      get() {
        return this.form.check_status == -1 ? "" : this.form.check_status;
      },
      set(val) {
        this.form.check_status = val;
      },
    },
    type: {
      get() {
        return this.form.type == -1 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>