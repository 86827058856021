<template>
  <div class="list-page">
    <InteractionListFilter v-model="filterForm" :uploadFilter="ok" />
    <list-tabs
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleClick"
    >
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div
        class="list-btn"
        v-if="filterForm.tab === 'stayCheck' || filterForm.tab === 'thenCheck'"
      >
        <el-button size="small" type="success" @click="handleChecked"
          >通过</el-button
        >
        <el-button size="small" type="warning" @click="handleNoCheck"
          >不通过</el-button
        >
        <el-button size="small" type="danger" @click="handleDel"
          >删除</el-button
        >
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'user'">
        <el-button size="small" type="primary" @click="handleBand"
          >禁言</el-button
        >
        <el-button size="small" @click="handleNoBand">解禁</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button size="small" type="primary" @click="handleReduct"
          >还原</el-button
        >
        <el-button size="small" type="danger" @click="handleDel"
          >删除</el-button
        >
      </div>
      <el-table
        :data="tbody"
        class="thead-light"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <!-- 勾选 -->
        <el-table-column
          class="select-check"
          fixed
          type="selection"
          width="55"
        ></el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          :width="filterForm.tab == 'stayCheck' ? '170' : '130'"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button type="text" size="small" @click="ReductRow(scope.row)">
                还原
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)"
                >删除</el-button
              >
            </el-button-group>
            <el-button-group
              v-else-if="
                filterForm.tab == 'stayCheck' || filterForm.tab == 'thenCheck'
              "
            >
              <el-button
                v-if="
                  scope.row.check_status === 0 || scope.row.check_status === 1
                "
                type="text"
                size="small"
                @click="CheckedRow(scope.row)"
              >
                通过
              </el-button>
              <el-button
                v-if="
                  scope.row.check_status === 0 || scope.row.check_status === 2
                "
                type="text"
                size="small"
                @click="NoCheckRow(scope.row)"
              >
                不通过
              </el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row)"
                >删除</el-button
              >
            </el-button-group>
            <el-button-group v-else>
              <el-button type="text" size="small" @click="ChatRow(scope.row)">
                互动内容
              </el-button>
              <el-button
                type="text"
                v-if="!scope.row.is_black"
                size="small"
                @click="BandRow(scope.row)"
              >
                禁言
              </el-button>
              <el-button
                type="text"
                v-else
                size="small"
                @click="NoBandRow(scope.row)"
              >
                解禁
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template
          v-for="(th, index) in thead[
            filterForm.tab === 'user' ? 'user' : 'interaction'
          ]"
        >
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :sortable="th.sortable"
            :key="index"
          >
            <template slot-scope="scope">
              <!-- 回复状态 -->
              <span v-if="th.prop === 'check_status'">
                <span
                  :class="[
                    'status-dot',
                    scope.row.check_status === 2
                      ? 'green'
                      : scope.row.check_status === 1
                      ? 'grey'
                      : 'yellow',
                  ]"
                ></span>
                {{
                  scope.row[th.prop] === 0
                    ? "待审核"
                    : scope.row[th.prop] === 1
                    ? "未通过"
                    : "已通过"
                }}
              </span>
              <!-- 用户状态 -->
              <span v-else-if="th.prop === 'is_black'">
                <span
                  :class="['status-dot', scope.row.is_black ? 'grey' : 'green']"
                ></span>
                {{ scope.row[th.prop] === 0 ? "可互动" : "被禁言" }}
              </span>
              <!-- 用户类型 -->
              <span v-else-if="th.prop === 'type'">{{
                scope.row.title_name ? "会员" : "游客"
              }}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
    <chat-dialog
      v-model="openChatDialog"
      :user_id="user_id"
      :activity_id="filterForm.id"
    ></chat-dialog>
  </div>
</template>

<script>
import InteractionListFilter from "../../components/ActivityList/InteractionListFilter";
import Pagination from "@/base/components/Default/Pagination";
import ChatDialog from "../../components/ActivityList/ChatDialog";
import {
  InteractionList,
  softDelete,
  Delete,
  putBack,
  Checked,
  NoCheck,
  BannedUser,
  NoBannedUser,
} from "../../api/activity-list/interaction";
export default {
  data() {
    return {
      tabArr: [
        { label: "待审核", name: "stayCheck", badge: 1 },
        { label: "已审核", name: "thenCheck" },
        { label: "互动用户", name: "user" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: {
        interaction: [
          { label: "内容ID", prop: "id", minWidth: 80 },
          { label: "互动内容", prop: "content", minWidth: 150 },
          { label: "用户名称", prop: "nickname", minWidth: 110 },
          { label: "架构/会内职务", prop: "title_name", minWidth: 180 },
          { label: "用户类型", prop: "type", minWidth: 80 },
          {
            label: "发布时间",
            prop: "create_time",
            minWidth: 200,
            sortable: true,
          },
          { label: "状态", prop: "check_status" },
        ],
        user: [
          { label: "ID", prop: "user_id" },
          { label: "用户名称", prop: "nickname", minWidth: 100 },
          { label: "架构/会内职务", prop: "title_name", minWidth: 180 },
          { label: "用户类型", prop: "type", minWidth: 80 },
          {
            label: "互动数量",
            prop: "chat_count",
            minWidth: 115,
            sortable: true,
          },
          { label: "用户状态", prop: "is_black", minWidth: 100 },
        ],
      },
      //筛选对象
      filterForm: {
        id: this.$route.params.id,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        user_status: -1, //用户状态
        check_stauts: -1, // 审核状态
        type: -1, //用户类型
        content_keyword: "", //内容关键字
        user_keyword: "", //用户名关键字
        tab: "stayCheck", //当前筛选tab
        page_size: 15, //每页数据数
      },
      //筛选缓存
      filters: {
        stayCheck: null,
        thenCheck: null,
        user: null,
        deleted: null,
      },
      user_id: 0,
      pageData: {},
      openChatDialog: false,
      ids: [],
      tbody: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取列表
    getInteractionList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      InteractionList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id || item.user_id);
        });
      }
    },
    // 批量通过互动
    handleChecked() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要通过选中数据吗？", "提示", {
            type: "info",
          })
          .then(() => {
            this.loading = true;
            Checked({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch(() => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表通过
    CheckedRow(row) {
      this.$msgbox
        .confirm("确定要通过此数据吗？", "提示", {
          type: "info",
        })
        .then(() => {
          this.loading = true;
          Checked({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 批量不通过互动
    handleNoCheck() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要不通过选中数据吗？", "提示", {
            type: "info",
          })
          .then(() => {
            this.loading = true;
            NoCheck({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch(() => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表不通过
    NoCheckRow(row) {
      this.$msgbox
        .confirm("确定要不通过此数据吗？", "提示", {
          type: "info",
        })
        .then(() => {
          this.loading = true;
          NoCheck({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 批量解禁用户
    handleNoBand() {
      const data = { user_id: this.selectArr, activity_id: this.filterForm.id };
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要解禁选中的用户吗？", "提示", {
            type: "info",
          })
          .then(() => {
            this.loading = true;
            NoBannedUser(data)
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表解禁
    NoBandRow(row) {
      const data = { user_id: row.user_id, activity_id: this.filterForm.id };
      this.$msgbox
        .confirm("确定要解禁此用户吗？", "提示", {
          type: "info",
        })
        .then(() => {
          this.loading = true;
          NoBannedUser(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 批量禁言
    handleBand() {
      const data = { user_id: this.selectArr, activity_id: this.filterForm.id };
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要禁言选中的用户吗？", "提示", {
            type: "info",
          })
          .then(() => {
            this.loading = true;
            BannedUser(data)
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表禁言
    BandRow(row) {
      const data = { user_id: row.user_id, activity_id: this.filterForm.id };
      this.$msgbox
        .confirm("确定要禁言此用户吗？", "提示", {
          type: "info",
        })
        .then(() => {
          this.loading = true;
          BannedUser(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (
          this.filterForm.tab === "stayCheck" ||
          this.filterForm.tab === "thenCheck"
        ) {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (
        this.filterForm.tab === "stayCheck" ||
        this.filterForm.tab === "thenCheck"
      ) {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (
        this.filterForm.tab === "stayCheck" ||
        this.filterForm.tab === "thenCheck"
      ) {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击查看互动内容
    ChatRow(row) {
      this.user_id = row.user_id;
      this.openChatDialog = true;
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          id: this.$route.params.id,
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          user_status: -1, //用户状态
          check_status: -1, // 审核状态
          type: -1, //用户类型
          content_keyword: "", //内容关键字
          user_keyword: "", //用户名关键字
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getInteractionList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getInteractionList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getInteractionList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getInteractionList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getInteractionList(pageData);
    },
  },
  created() {
    this.getInteractionList(this.filterForm); //获取列表数据
  },
  components: { InteractionListFilter, Pagination, ChatDialog },
};
</script>

<style lang="scss" scoped>
.container {
  .page-body {
    padding: 0 20px 20px;
  }
}
</style>
