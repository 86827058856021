<template>
  <el-dialog title="互动内容" :visible.sync="openDialog" width="600px" :show-close="true"
    class="dialog-vertical" @close="cancel">
    <div class="member-list">
      <el-table :data="interactionList" class="thead-light" stripe style="width: 100%" :height="300"
        v-loading="loading">
        <!-- 序号 -->
        <el-table-column width="60" type="index" :index="indexMethod" label="序号"></el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index">
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagination" v-if="pageData.last_page >1">
        <el-pagination background layout="prev, pager, next" :page-size="pageData.per_page"
          :total="pageData.total" :current-page="Number(pageData.current_page)" @current-change="pageChange">
        </el-pagination>
        <el-input class="number-input" type="number" min="1" :max="pageData.last_page" auto-complete="off"
          v-model="pageData.current_page"></el-input>
        <el-button class="page-jump" type="primary" @click="changePage">GO</el-button>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { chatContent } from "../../api/activity-list/interaction";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
    },
    activity_id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "互动内容", prop: "content", minWidth: 200 },
        { label: "发布时间", prop: "create_time", minWidth: 150 },
      ],
      interactionList: [], // 互动列表
      //数据请求对象
      requesData: {
        user_id: 0,
        activity_id: 0,
        page_size: 15, //每页多少条数据
        page: 1,
      },
      pageData: {
        total: 15,
        per_page: 15,
        current_page: 1,
        last_page: 1,
      },
      AllSourceList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取互动列表
    getChatContent() {
      this.loading = true;
      chatContent(this.requesData).then((res) => {
        const { data } = res;
        this.interactionList = data.data;
        this.pageData = data;
        this.loading = false;
      });
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    // 点击改变页数
    pageChange(e) {
      this.pageData.current_page = e;
      this.getChatContent();
    },
    // 输入改变页数
    changePage() {
      if (
        Number(this.pageData.current_page) > 0 &&
        Number(this.pageData.current_page) <= Number(this.pageData.last_page)
      ) {
        this.getChatContent();
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  watch: {
    user_id(val) {
      this.requesData.user_id = val;
      this.requesData.activity_id = this.activity_id;
      this.requesData.page = 1;
      this.getChatContent();
    },
    pageData: {
      deep: true,
      handler(val) {
        this.requesData.page = val.current_page;
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  height: 30;
  margin-top: 20px;
  align-items: center;
  .number-input {
    max-width: 60px;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none;
    }
    ::v-deep.el-input__inner {
      height: 28px;
      border: 1px solid #dcdfe6;
      padding: 0 10px;
    }
  }
  .page-jump {
    height: 28px;
    line-height: 0;
    padding: 10px;
    margin-left: 10px;
  }
}
</style>
